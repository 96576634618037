//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    SmoothExpandDiv: () => import('@/containers/homepage/SmoothExpandDiv'),
    BlogList: () => import('@/containers/homepage/BlogList.vue'),
  },
  props: {
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
}
